import {
  HorizontalCardsBgV2,
  RichText,
  toImageProps,
  EHorizontalCardBgV2Type,
  EHorizontalCardBgV2Theme,
  isRichTextContentEmpty,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type THorizontalCardsBgV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'horizontal-card-bg-v2' }
>;

const HorizontalCardsBgV2Connected: React.FC<
  THorizontalCardsBgV2ConnectedProps
> = ({ cards, divider }) => {
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const horizontalCards = useMemo(
    () =>
      cards?.map(
        ({
          blocks = [],
          type,
          theme,
          buttons,
          eyebrows,
          image,
          subtitle,
          smallImages,
          title,
          tags,
          iconTop,
          id,
          imageRounding,
          imageBackground,
        }) => ({
          id,
          type: type as EHorizontalCardBgV2Type,
          buttons: toCardButtons(buttons, currentLocale, appDispatch),
          eyebrows: eyebrows?.map(({ eyebrow }) => eyebrow),
          image: toImageProps(image),
          subtitle:
            subtitle && !isRichTextContentEmpty(subtitle) ? (
              <RichText content={subtitle} />
            ) : null,
          title:
            title && !isRichTextContentEmpty(title) ? (
              <RichText content={title} />
            ) : null,
          smallImages: smallImages?.map(
            smallImage => toImageProps(smallImage.image) as ImageProps,
          ),
          tags: tags?.map(({ text }) => text),
          icon: toImageProps(iconTop),
          blocks: blocks?.map(block => ({
            icon: toImageProps(block.icon),
            title: block.title,
            description:
              block.description &&
              !isRichTextContentEmpty(block.description) ? (
                <RichText content={block.description} />
              ) : null,
          })),
          theme: theme as EHorizontalCardBgV2Theme,
          imageRounding: imageRounding === 'Yes',
          imageBackground,
        }),
      ),
    [cards, appDispatch, currentLocale],
  );

  return (
    <HorizontalCardsBgV2
      cards={horizontalCards}
      hasDivider={divider === 'Yes'}
    />
  );
};

export default HorizontalCardsBgV2Connected;
